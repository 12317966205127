<template>
    <v-card
        @click.passive="emitHelpMode"
        @focus.capture="emitHelpMode"
        height="100%"
        :elevation="lightVariant ? 0 : 1"
        :disabled="disabled"
    >
        <v-card-text class="px-0" :class="{'py-3 pl-3': !lightVariant, 'py-0': lightVariant}" @click="menu = true">
            <v-menu
                ref="dateMenu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :disabled="disabled"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :disabled="loading || disabled"
                        :loading="loading"
                        v-model="dateFormated"
                        :label="label"
                        :hint="hint"
                        :persistent-hint="!!hint !== null"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        :filled="!lightVariant"
                        background-color="transparent"
                        :prepend-icon="prependIcon"
                        :outlined="lightVariant"
                        hide-details
                    >
                    </v-text-field>
                </template>
                <v-date-picker
                    v-model="model"
                    :disabled="loading || disabled"
                    :locale="$i18n.locale"
                    scrollable
                    :first-day-of-week="1"
                    :type="pickerType"
                    :min="minDate"
                    :max="maxDate"
                    @input="menu = false"
                    @change="$emit('modified', $event)"
                    >
                </v-date-picker>
            </v-menu>
        </v-card-text>
    </v-card>
</template>

<script>
import { dateTypes, parseISO, formatDate } from '@/utils/dateFns';

export default {
    name: 'InputLeaDate',
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: String,
            default: null
        },
        formScope: {
            type: String,
            default: null
        },
        pickerType: {
            type: String,
            default: 'date',
        },
        minDate: {
            type: String,
            default: null,
        },
        maxDate: {
            type: String,
            default: null,
        },
        prependIcon: {
            type: String,
            default: 'mdi-calendar',
        },
        lightVariant: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
    },
    data() {
        return {
            menu: false
        };
    },
    computed: {
        dateFormated: {
            get() {
                return this.formatDate(this.model)
            },
            set(val) {
                // triggered by textfields clear button
                this.model = val;
            }
        },
        model: {
            get() {
                return this.value ? this.value : null;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        formatDate (date) {
            if (!date) return;

            if(this.pickerType === 'month') {
                date = formatDate(parseISO(date), dateTypes.fullDateMonthName)
            }
            return date;
        },
        emitHelpMode () {
            if(this.fieldName === null) {
                return;
            }
            if (this.helpModeText) {
                this.$emit('help-mode', {
                    title: this.label,
                    text: this.helpModeText,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            } else {
                this.$emit('help-mode', {
                    step: this.step,
                    name: this.fieldName,
                    group: this.groupName,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            }
        }
    },
    watch: {
        menu() {
            this.emitHelpMode();
        },
    },
};
</script>
