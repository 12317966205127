<template>
  <div>
    <v-card color="transparent" flat tile class="px-2 mb-1">
      <v-card-text class="px-2 py-0 pb-3">
          <v-row class="fill-height" no-gutters>
            <template v-if="leaMaster">
              <v-col cols="12">
                <LeaDropdown
                    type="autocomplete"
                    :formScope="formScope"
                    :step="1"
                    fieldName="department"
                    :label="$t('vvtProcessEditor.basics.department.label')"
                    :hint="$t('vvtProcessEditor.basics.department.hint')"
                    :helpModeText="$t('vvtProcessEditor.basics.department.helpMode')"
                    :create="create"
                    multiple
                    chips
                    deletable-chips
                    v-model="departmentCategoryModel"
                    @help-mode="$emit('help-mode', $event)"
                    :items="filteredDepartmentCategories"
                    clearable
                    :disabled="!canEdit"
                    :loading="$wait.is('fetch departments')"
                    :required="leaMaster"
                  >
                    <template v-slot:item="{ item }">
                        <div class="w100">
                          {{ item.title }}<v-btn v-if="item.feature" x-small depressed disabled class="ml-3">{{ item.feature }}</v-btn>
                        </div>
                    </template>
                  </LeaDropdown>
              </v-col>
            </template>
            <template v-if="!template">
                <v-col
                  cols="12"
                  md="6"
                  class="pb-3"
                  :class="{
                    'pr-3': $vuetify.breakpoint.mdAndUp
                    }"
                >
                    <LeaDropdown
                      type="autocomplete"
                      :formScope="formScope"
                      :step="1"
                      fieldName="company"
                      :label="$t('vvtProcessEditor.basics.company.label')"
                      :hint="$t('vvtProcessEditor.basics.company.hint')"
                      :helpModeText="$t('vvtProcessEditor.basics.company.helpMode')"
                      :create="create"
                      v-model="companyModel"
                      @help-mode="$emit('help-mode', $event)"
                      :items="allCompanies"
                      :item-disabled="function(e) {
                          return !!e.canCreate === false;  
                      }"
                      clearable
                      :disabled="!canEdit"
                      :loading="$wait.is('fetch companies')"
                      :required="true"
                      @hook:mounted="cleanProcessorCompanies()"
                      @modified="cleanProcessorCompanies()"
                    />
                  </v-col>
            </template>
            <template v-if="!leaMaster">
                <v-col 
                  cols="12"
                  :md="template ? 12 : 6"
                  class="pb-3">
                    <LeaAutocomplete
                      type="autocomplete"
                      :formScope="formScope"
                      :step="1"
                      fieldName="countriesOfAffectedPersons"
                      :label="$t('vvtProcessEditor.basics.countriesOfAffectedPersons.label')"
                      :hint="$t('vvtProcessEditor.basics.countriesOfAffectedPersons.hint')"
                      :helpModeText="$t('vvtProcessEditor.basics.countriesOfAffectedPersons.helpMode')"
                      :create="create"
                      v-model="countriesOfAffectedPersonsModel"
                      @help-mode="$emit('help-mode', $event)"
                      :items="countries"
                      :item-value="function(e) {
                          return e.countryCode
                      }"
                      item-text="countryName"
                      clearable
                      :disabled="!canEdit"
                      :required="false"
                      multiple
                    >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index <= 1">
                        <span v-if="index === 1">, </span><span>{{ item.countryName }}</span>
                      </div>
                      <span
                        v-if="index === 2"
                        class="grey--text caption pl-1"
                        v-text="$tc('xOthers', countriesOfAffectedPersonsModel.length - 2)"
                      />
                    </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click.stop="toggleSelectAllCountries()">
                            <v-list-item-action>
                                <v-icon :color="countriesOfAffectedPersonsModel.length > 0 ? 'primary' : ''" v-text="countriesSelectAllIcon" />
                            </v-list-item-action>
                            <v-list-item-title v-t="'selectAllCountries'" />
                        </v-list-item>
                        <v-list-item ripple @click.stop="toggleSelectEeaCountries()">
                            <v-list-item-action>
                                <v-icon :color="countriesOfAffectedPersonsEeaModel.length > 0 ? 'primary' : ''" v-text="countriesSelectEeaIcon" />
                            </v-list-item-action>
                            <v-list-item-title v-t="'selectAllEeaCountries'" />
                        </v-list-item>
                        <v-list-item ripple @click.stop="toggleSelectEuCountries()">
                            <v-list-item-action>
                                <v-icon :color="countriesOfAffectedPersonsEuModel.length > 0 ? 'primary' : ''" v-text="countriesSelectEuIcon" />
                            </v-list-item-action>
                            <v-list-item-title v-t="'selectAllEuCountries'" />
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </LeaAutocomplete>
                </v-col>
            </template>
            <template v-if="!leaMaster && (template || useDomain)">
                <v-col 
                  cols="12"
                  :md="template ? 12 : useDomainAndDepartment ? 6 : 12"
                  :lg="template ? 12 : useDomainAndDepartment ? 6 : 4"
                  :xl="template ? 12 : useDomainNotDepartment || useDepartmentNotDomain ? 6 : 4"
                  class="pb-3"
                  :class="{
                    'pr-md-3': !template && useDepartment, 'pr-lg-3': !template && (useDepartment || useDomain)}"
                    >
                    <LeaDropdown
                      type="autocomplete"
                      :formScope="formScope"
                      :step="1"
                      fieldName="domain"
                      :label="$t('vvtProcessEditor.basics.domain.label')"
                      :hint="$t('vvtProcessEditor.basics.domain.hint')"
                      :helpModeText="$t('vvtProcessEditor.basics.domain.helpMode')"
                      :create="create"
                      v-model="domainModel"
                      @help-mode="$emit('help-mode', $event)"
                      :items="domainItems"
                      clearable
                      :disabled="!canEdit"
                      :loading="$wait.is('fetch domains')"
                      :required="template"
                    />
                </v-col>
            </template>
            <template v-if="!template && useDepartment">
                  <v-col
                    cols="12"
                    :md="useDomainAndDepartment ? 6 : 12"
                    :lg="useDomainAndDepartment ? 6 : 4"
                    :xl="useDomainNotDepartment || useDepartmentNotDomain ? 6 : 4"
                    class="pb-3"
                    :class="{
                      'pr-lg-3': !template && !useDomain, 'pr-xl-3': !template}"
                      >
                    <LeaDropdown
                      type="autocomplete"
                      :formScope="formScope"
                      :step="1"
                      fieldName="department"
                      :label="$t('vvtProcessEditor.basics.department.label')"
                      :hint="$t('vvtProcessEditor.basics.department.hint')"
                      :helpModeText="$t('vvtProcessEditor.basics.department.helpMode')"
                      :create="create"
                      v-model="departmentModel"
                      @help-mode="$emit('help-mode', $event)"
                      :items="companyDepartments"
                      clearable
                      :disabled="!canEdit"
                      :loading="$wait.is('fetch departments')"
                      :required="false"
                    />
                </v-col>
            </template>
            <template v-if="!leaMaster">
                <v-col
                    cols="12"
                    sm="6"
                    :lg="template ? 6 : useDomainNotDepartment || useDepartmentNotDomain ? 4 : 6"
                    :xl="template ? 6 : useDomainNotDepartment || useDepartmentNotDomain ? 3 : 2"
                    class="pl-0 pt-0 pb-3 pr-sm-3">
                    <LeaSwitch
                        :disabled="!canEdit"
                        v-model="controllerModel"
                        :formScope="formScope"
                        :step="1"
                        fieldName="controller"
                        :label="$t('vvtProcessEditor.basics.controller.label')"
                        :hint="$t('vvtProcessEditor.basics.controller.hint')"
                        :helpModeText="$t('vvtProcessEditor.basics.controller.helpMode')"
                        :label-on="$t('yes')"
                        :label-off="$t('no')"
                        :create="create"
                        color="green"
                        @help-mode="$emit('help-mode', $event)"
                        @modified="changedRecordType()"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    :lg="template ? 6 : useDomainNotDepartment || useDepartmentNotDomain ? 4 : 6"
                    :xl="template ? 6 : useDomainNotDepartment || useDepartmentNotDomain ? 3 : 2"
                    class="pb-3"
                    :class="{
                      'pr-xl-3': !template && !useDomain && !useDepartment}"
                      >
                    <LeaSwitch
                        :disabled="!canEdit"
                        v-model="processorModel"
                        :formScope="formScope"
                        :step="1"
                        fieldName="processor"
                        :label="$t('vvtProcessEditor.basics.processor.label')"
                        :hint="$t('vvtProcessEditor.basics.processor.hint')"
                        :helpModeText="$t('vvtProcessEditor.basics.processor.helpMode')"
                        :label-on="$t('yes')"
                        :label-off="$t('no')"
                        :create="create"
                        color="green"
                        @help-mode="$emit('help-mode', $event)"
                        @modified="changedRecordType()"
                    />
                </v-col>
                <template v-if="processorModel">
                    <v-col
                        cols="12"
                        md="6"
                        :xl="(useDomainNotDepartment || useDepartmentNotDomain) || useDomainAndDepartment ? 6 : 4"
                        class="pb-3 pr-md-3"
                    >
                        <LeaDropdown
                            type="autocomplete"
                            :disabled="!canEdit"
                            v-model="processorCompaniesModel"
                            :items="processorCompanyItems"
                            :formScope="formScope"
                            :step="1"
                            fieldName="processorCompanies"
                            :label="$t('vvtProcessEditor.basics.processorCompanies.label')"
                            :hint="$t('vvtProcessEditor.basics.processorCompanies.hint')"
                            :helpModeText="$t('vvtProcessEditor.basics.processorCompanies.helpMode')"
                            :create="create"
                            @help-mode="$emit('help-mode', $event)"
                            clearable
                            :required="false"
                            multiple
                            chips
                            deletable-chips
                        />
                    </v-col>
                    <v-col
                        v-if="getCompanyGroupSettings('useVendor')"
                        cols="12"
                        md="6"
                        :xl="(useDomainNotDepartment || useDepartmentNotDomain) || useDomainAndDepartment ? 6 : 4"
                        class="pb-3"
                    >
                        <LeaDropdown
                            type="autocomplete"
                            :disabled="!canEdit"
                            v-model="processorVendorCompaniesModel"
                            :items="vendorItems"
                            :formScope="formScope"
                            :step="1"
                            fieldName="processorVendorCompanies"
                            :label="$t('vvtProcessEditor.basics.processorVendorCompanies.label')"
                            :hint="$t('vvtProcessEditor.basics.processorVendorCompanies.hint')"
                            :helpModeText="$t('vvtProcessEditor.basics.processorVendorCompanies.helpMode')"
                            :create="create"
                            @help-mode="$emit('help-mode', $event)"
                            clearable
                            :required="false"
                            item-text="name"
                            multiple
                            chips
                            deletable-chips
                        />
                    </v-col>
                    <v-col
                      cols="12"
                      :md="getCompanyGroupSettings('useVendor') ? 12 : 6"
                      class="pb-3"
                    >
                        <LeaTextArea
                            :disabled="!canEdit"
                            :formScope="formScope"
                            :step="1"
                            :rows="5"
                            fieldName="processorOutsideCompanies"
                            :label="$t('vvtProcessEditor.basics.processorOutsideCompanies.label')"
                            :hint="$t('vvtProcessEditor.basics.processorOutsideCompanies.hint')"
                            :helpModeText="$t('vvtProcessEditor.basics.processorOutsideCompanies.helpMode')"
                            :create="create"
                            v-model="processorOutsideCompaniesModel"
                            @help-mode="$emit('help-mode', $event)"
                        />
                    </v-col>
                </template>
                <v-col v-if="!template" cols="12" class="pt-8">
                  <ContactAssignment v-model="contactAssignmentModel" :forcedLanguage="forcedLanguage" :recordLanguage="recordLanguage" :create="create" :canEdit="canEdit" />
                </v-col>
            </template>
          </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LeaSwitch from '@/components/Input/LeaSwitch';
import LeaTextArea from '@/components/Input/LeaTextArea';
import LeaAutocomplete from '@/components/Input/LeaAutocomplete';
import LeaDropdown from '@/components/Input/LeaDropdown';
import ModelMixin from '@/components/vvt/ModelMixin';
import ContactAssignment from './ContactAssignment';

export default {
  mixins: [ModelMixin],
  inject: {
      $validator: '$validator',
  },
  components: {
    LeaSwitch,
    LeaTextArea,
    LeaAutocomplete,
    LeaDropdown,
    ContactAssignment
  },
  i18n: {
      messages: {
          en: require('@/locales/vvt/ProcessEditor/en.json'),
          de: require('@/locales/vvt/ProcessEditor/de.json'),
      },
  },
  props: {
    formScope: {
      type: String,
      default: null
    },
    forcedLanguage: {
      type: String,
      default: null
    },
    recordLanguage: {
        type: String,
        default: null
    },
    create: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    template: {
        type: Boolean,
        default: false,
    },
    leaMaster: {
        type: Boolean,
        default: false,
    },
    contactItemCategories: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      companyDepartments: [],
      processorCompanyItems: [],
    }
  },
  computed: {
    ...mapGetters({
        getEventStore: 'eventStore/getDataByKey',
        getCompanyGroupSettings: 'companyGroupSettings/get',
        get: 'processingActivityModel/getProperty',
        countries: 'countries/getItems',
        allCompanies: 'companies/getAllCompanies',
        domainItems: 'domain/getItems',
        departmentCategories: 'departmentCategories/getItems',
        vendorItems: 'vendor/getItems',
    }),
    filteredDepartmentCategories() {
      const items = [...this.departmentCategories];
      
      return items.sort((valA, valB) => {
          return valA['feature'] === null && valB['feature'] !== null ? -1 : 1;
      });
    },
    canEdit () {
      return this.get('canEdit');
    },
    useDomain () {
        if (this.domainModel) {
            return true;
        }
        return this.getCompanyGroupSettings('useDomainInProcessingActivity');
    },
    useDepartment () {
        if (this.departmentModel && this.companyDepartments.find(x => x.id === this.departmentModel)) {
            return true;
        }
        return this.getCompanyGroupSettings('useDepartmentInProcessingActivity');
    },
    useDomainAndDepartment () {
      return this.useDomain && this.useDepartment;
    },
    useDomainNotDepartment () {
      return this.useDomain && !this.useDepartment;
    },
    useDepartmentNotDomain () {
      return !this.useDomain && this.useDepartment;
    },
    departmentCategoryModel: {
        get() {
            return this.get('departmentCategory');
        },
        set(val) {
            this.set({ property: 'departmentCategory', data: val });
        },
    },
    domainModel: {
        get() {
            return this.get('domain');
        },
        set(val) {
            this.set({ property: 'domain', data: val });
        },
    },
    companyModel: {
        get() {
          return this.get('company');
        },
        set(val) {
            this.set({ property: 'company', data: val });
        },
    },
    departmentModel: {
        get() {
            return this.get('department');
        },
        set(val) {
            this.set({ property: 'department', data: val });
        },
    },
    countriesOfAffectedPersonsModel: {
        get() {
            return this.get('countriesOfAffectedPersons');
        },
        set(val) {
            this.set({ property: 'countriesOfAffectedPersons', data: val });
        },
    },
    countriesAllSelected() {
        return this.countriesOfAffectedPersonsModel.length === this.countries.length;
    },
    countriesSomeSelected() {
        return this.countriesOfAffectedPersonsModel.length > 0 && !this.countriesAllSelected;
    },
    countriesSelectAllIcon() {
        if (this.countriesAllSelected) return 'check_box';
        if (this.countriesSomeSelected) return 'indeterminate_check_box';
        return 'check_box_outline_blank';
    },
    countriesAllEuSelected() {
        return this.countriesOfAffectedPersonsEuModel.length === this.countries.filter(x=>x.eu).length;
    },
    countriesSomeEuSelected() {
        return this.countriesOfAffectedPersonsEuModel.length > 0 && !this.countriesAllEuSelected;
    },
    countriesSelectEuIcon() {
        if (this.countriesAllEuSelected) return 'check_box';
        if (this.countriesSomeEuSelected) return 'indeterminate_check_box';
        return 'check_box_outline_blank';
    },
    countriesOfAffectedPersonsEuModel () {
      return this.countries.filter(x => x.eu && this.countriesOfAffectedPersonsModel.includes(x.countryCode));
    },
    countriesAllEeaSelected() {
        return this.countriesOfAffectedPersonsEeaModel.length === this.countries.filter(x=>(x.eu || x.eea)).length;
    },
    countriesSomeEeaSelected() {
        return this.countriesOfAffectedPersonsEeaModel.length > 0 && !this.countriesAllEeaSelected;
    },
    countriesSelectEeaIcon() {
        if (this.countriesAllEeaSelected) return 'check_box';
        if (this.countriesSomeEeaSelected) return 'indeterminate_check_box';
        return 'check_box_outline_blank';
    },
    countriesOfAffectedPersonsEeaModel () {
      return this.countries.filter(x => (x.eu || x.eea) && this.countriesOfAffectedPersonsModel.includes(x.countryCode));
    },
    controllerModel: {
        get() {
            return this.get('controller');
        },
        set(val) {
            this.set({ property: 'controller', data: val });
        },
    },
    processorModel: {
        get() {
            return this.get('processor');
        },
        set(val) {
            this.set({ property: 'processor', data: val });
        },
    },
    processorCompaniesModel: {
        get() {
            return this.get('processorCompanies');
        },
        set(val) {
            this.set({ property: 'processorCompanies', data: val });
        },
    },
    processorVendorCompaniesModel: {
        get() {
            return this.get('processorVendorCompanies');
        },
        set(val) {
            this.set({ property: 'processorVendorCompanies', data: val });
        },
    },
    processorOutsideCompaniesModel: {
        get() {
            return this.get('processorOutsideCompanies');
        },
        set(val) {
            this.set({ property: 'processorOutsideCompanies', data: val });
        },
    },
    contactAssignmentModel: {
        get() {
          // merge all types of persons
          let contactItems = this.get('contactItems');
          contactItems = contactItems.map(x => {
            const prefix = x.title ? x.title + ' ' : '';
            x.fullName = `${prefix}${x.firstName} ${x.lastName}`;
            return x;
          });
          return contactItems;
        },
        set(val) {
            this.set({ property: 'contactItems', data: val });
        },
    },

  },
  methods: {
      ...mapActions({
          set: 'processingActivityModel/setProperty',
          fetchDepartmentsByCompany: 'companyDepartments/fetchByCompanyId'
      }),
      getDisabledProcessorCompanies() {
            return this.allCompanies.map(x => {
                x.disabled = this.companyModel === x.id;
                return x;
            })
      },
      cleanProcessorCompanies() {
        this.$delete(this.processorCompaniesModel, this.processorCompaniesModel.findIndex(x => x === this.companyModel));
        this.processorCompanyItems = this.getDisabledProcessorCompanies();
      },
      changedRecordType() {
        this.$nextTick(() => {
          if(!this.controllerModel && !this.processorModel) {
              this.controllerModel = true;
          }
        });
      },
      toggleSelectAllCountries() {
        this.$nextTick(() => {
            if (this.countriesAllSelected) {
                this.countriesOfAffectedPersonsModel = [];
            } else {
                this.countriesOfAffectedPersonsModel = this.countries.map(x => x.countryCode);
            }
        });
      },
      toggleSelectEuCountries () {
        this.$nextTick(() => {
            if (this.countriesAllEuSelected) {
                this.countriesOfAffectedPersonsModel = [];
            } else {
                this.countriesOfAffectedPersonsModel = this.countries.filter(x=>x.eu).map(x => x.countryCode);
            }
        });
      },
      toggleSelectEeaCountries () {
        this.$nextTick(() => {
            if (this.countriesAllEeaSelected) {
                this.countriesOfAffectedPersonsModel = [];
            } else {
                this.countriesOfAffectedPersonsModel = this.countries.filter(x=>(x.eu || x.eea)).map(x => x.countryCode);
            }
        });
      },
      fetchDepartmentsByCompanyId (companyId) {
          return new Promise(resolve => {
            this.$wait.start('fetch departments');
            let payload = {
              id: companyId
            }
            if (this.forcedLanguage) {
              payload.forcedLanguage = this.forcedLanguage;
            }
            this.fetchDepartmentsByCompany(payload).then(result => {
              if (result.data) {
                this.companyDepartments = result.data && result.data.departments ? result.data.departments : [];
                this.$wait.end('fetch departments');
                resolve(this.companyDepartments);
              }
            });
          });
      },
  },
  watch: {
    companyModel (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const oldDepartments = this.companyDepartments;
        const oldDepartment = oldDepartments.find(x => x.id === this.get('department'))
        this.fetchDepartmentsByCompanyId(newVal).then(newDepartments => {
          if (oldDepartment) {
            const newDepartment = newDepartments.find(x => x.title === oldDepartment.title);
            if (newDepartment) {
              this.departmentModel = newDepartment.id;
            }
          }
        });
        const objCompany = this.allCompanies.find(x => x.id === newVal);
        const objOldCompany = oldVal ? this.allCompanies.find(x => x.id === oldVal) : null;

        if (
          objCompany && objCompany.countryCode &&
          (
            (!this.countriesOfAffectedPersonsModel || !this.countriesOfAffectedPersonsModel.length) ||
            (objOldCompany && this.countriesOfAffectedPersonsModel.length === 1 && this.countriesOfAffectedPersonsModel[0] === objOldCompany.countryCode)
          )
        ) {
          this.countriesOfAffectedPersonsModel = [objCompany.countryCode];
        }
      }
    },
     forcedLanguage (newLang, oldLang) {
       if (newLang !== oldLang) {
         this.fetchDepartmentsByCompanyId(this.companyModel);
       }
     }
  },
  mounted () {
    let selectedCompanyId = this.getEventStore('selectedCompanyId');
    if (selectedCompanyId) {
      let companyItem = this.allCompanies.find(x => x.id === selectedCompanyId);
  
      if (companyItem && companyItem.canCreate && this.create && !this.template && selectedCompanyId) {
        this.companyModel = selectedCompanyId;
      }
    }
    if(this.companyModel) {
        this.fetchDepartmentsByCompanyId(this.companyModel);
    }
  }
}
</script>
