<template>
  <v-card
    @click.passive="emitHelpMode"
    @focus.capture="emitHelpMode"
    align-start
    height="100%"
    elevation="1"
  >
        <v-card-title primary-title class="pt-3 py-0">
            <div
                class="v-label caption"
                v-text="title || label"
            ></div>
      </v-card-title>
        <v-card-text class="px-3 pb-3">
            <v-switch
                v-model="model"
                :hint="hint"
                persistent-hint
                :error-messages="
                    errors.collect(
                        formScope + '.' + fieldName + rowId
                    )
                "
                :data-vv-name="fieldName + rowId"
                :data-vv-scope="formScope"
                v-validate="required ? 'required' : ''"
                :required="required"
                inset
                dense
                :color="color"
                :disabled="loading || disabled || disabledComp"
                :loading="loading"
                class="v-text-field mt-0"
                @change="$emit('modified', $event)"
            >
                <template v-slot:label>
                    <div class="pb-5" v-text="labelOn && labelOff && model ? labelOn : labelOn && labelOff && !model ? labelOff : label" />
                </template>
                <template v-slot:append>
                    <v-tooltip v-if="!helpMode && companyGroupHelp && companyGroupHelp.fieldIcon" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                        v-on="on"
                        icon
                        large
                        color="primary"
                        class="mr-3"
                        @click.prevent.capture="setHelpMode(true)"
                        >
                        <v-icon v-text="companyGroupHelp.fieldIcon" />
                        </v-btn>
                    </template>
                        {{ $t('furtherInformation') }}
                    </v-tooltip>
                </template>
            </v-switch>
      </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
const MAIN_ENTITY = 'App\\Entity\\ProcessingActivity\\ProcessingActivity';

export default {
    name: 'LeaSwitch',
    inject: {
        $validator: '$validator',
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        create: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        labelOn: {
            type: String,
            default: null,
        },
        labelOff: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        value: {
            type: Boolean,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'primary',
        },
    },
    data () {
        return {
            disabledComp: false
        }
    },
    methods: {
        ...mapActions({
          setHelpMode: 'processingActivityModel/setHelpModeState',
        }),
        disable () {
            this.disabledComp = true;
        },
        enable () {
            this.disabledComp = false;
        },
        emitHelpMode () {
            if (this.helpModeText) {
                this.$emit('help-mode', {
                    title: this.label || this.title,
                    text: this.helpModeText,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            } else {
                this.$emit('help-mode', {
                    step: this.step,
                    name: this.fieldName,
                    group: this.groupName,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            }
        }
    },
    computed: {
        ...mapGetters({
            helpMode: 'processingActivityModel/getHelpModeState',
            getCompanyHelp: 'companyGroupHelp/getByEntityAndFieldName',
        }),
        companyGroupHelp() {
            return this.getCompanyHelp(MAIN_ENTITY, this.fieldName, this.childFieldName);
        },
        model: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        disabled (val) {
            this.disabledComp = val;
        }
    }
};
</script>
