<template>
    <div>
        <v-card flat tile color="secondary" class="pb-3">
            <v-toolbar dense flat color="transparent" style="min-height:72px;">
                <v-toolbar-title class="pl-0 pt-3 text-subtitle-1" v-t="'vvtProcessEditor.basics.nameLanguageAndDescriptionHeadline'" />
            </v-toolbar>
            <v-card flat tile color="transparent" class="px-2 mb-1">
                <v-card-text class="px-2 py-0">
                    <v-row class="fill-height" no-gutters>
                        <v-col 
                            cols="12"
                            md="6"
                            :lg="useAuthorityReportRelevance ? 4 : 6"
                            :class="{ 'pr-3': $vuetify.breakpoint.mdAndUp }"
                            class="pb-3">
                            <LeaTextField
                                :disabled="!canEdit"
                                :formScope="formScope"
                                fieldName="internalId"
                                :step="1"
                                :label="$t('vvtProcessEditor.basics.internalId.label')"
                                :hint="$t('vvtProcessEditor.basics.internalId.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.tools.helpMode')"
                                v-model="internalIdModel"
                                @help-mode="$emit('help-mode', $event)"
                            />
                        </v-col>
                        <v-col
                            :cols="useAuthorityReportRelevance ? 6 : 12"
                            :md="useAuthorityReportRelevance ? 3 : 6"
                            lg="2"
                            :class="{'pr-3': $vuetify.breakpoint.lgAndUp || useAuthorityReportRelevance }"
                            class="pl-0 pt-0 pb-3">
                            <LeaDropdown
                                type="select"
                                :disabled="!canEditExtended && !template"
                                :formScope="formScope"
                                :step="1"
                                fieldName="status"
                                :label="$t('vvtProcessEditor.basics.status.label')"
                                :hint="$t('vvtProcessEditor.basics.status.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.status.helpMode')"
                                :create="create"
                                v-model="statusModel"
                                @help-mode="$emit('help-mode', $event)"
                                :items="[
                                    {
                                        status: 'draft', title: $t('states.draft')
                                    },
                                    {
                                        status: 'review', title: $t('states.review')
                                    },
                                    {
                                        status: 'approval', title: $t('states.approval')
                                    },
                                    {
                                        status: 'release', title: $t('states.release')
                                    },
                                    {
                                        status: 'finalised', title: $t('states.finalised')
                                    },
                                    {
                                        status: 'archived', title: $t('states.archived')
                                    }
                                ]"
                                item-value="status"
                                item-text="title"
                                required
                                >
                                <template v-slot:selection="{ item }">
                                    <v-chip class="ml-0" label small :color="$getStatusColorClass(item.status)">{{ item.title }}</v-chip>
                                </template>
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item  v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            <v-chip label small :color="$getStatusColorClass(item.status)">{{ item.title }}</v-chip>
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </LeaDropdown>
                        </v-col>
                        <v-col
                            v-if="useAuthorityReportRelevance"
                            cols="6"
                            md="3"
                            lg="2"
                            :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }"
                            class="pb-3">
                            <LeaSwitch
                                :disabled="!canEditExtended || template"
                                v-model="authorityReportRelevanceModel"
                                :formScope="formScope"
                                :step="1"
                                fieldName="authorityReportRelevance"
                                :label="$t('vvtProcessEditor.basics.authorityReportRelevance.label')"
                                :hint="$t('vvtProcessEditor.basics.authorityReportRelevance.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.authorityReportRelevance.helpMode')"
                                :label-on="$t('yes')"
                                :label-off="$t('no')"
                                :create="create"
                                color="green"
                                @help-mode="$emit('help-mode', $event)"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            lg="2"
                            :class="{ 'pr-3': $vuetify.breakpoint.smAndUp }"
                            class="pl-0 pt-0 pb-3">
                            <LeaDropdown
                                type="select"
                                :disabled="!canEditExtended || template"
                                :formScope="formScope"
                                :step="1"
                                fieldName="priority"
                                :label="$t('vvtProcessEditor.basics.priority.label')"
                                :hint="$t('vvtProcessEditor.basics.priority.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.priority.helpMode')"
                                :create="create"
                                v-model="priorityModel"
                                @help-mode="$emit('help-mode', $event)"
                                :items="[
                                    {
                                        priority: 'HIGH',
                                        title: $t('vvtProcessEditor.basics.priority.priorities.HIGH')
                                    },
                                    {
                                        priority: 'MEDIUM',
                                        title: $t('vvtProcessEditor.basics.priority.priorities.MEDIUM')
                                    },
                                    {
                                        priority: 'LOW',
                                        title: $t('vvtProcessEditor.basics.priority.priorities.LOW')
                                    }
                                ]"
                                item-value="priority"
                                item-text="title"
                                clearable>
                                <template v-slot:selection="{ item }">
                                    <v-chip class="ml-0" label small :color="$getPriorityColorClass(item.title)">{{ item.title }}</v-chip>
                                </template>
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item  v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            <v-chip label small :color="$getPriorityColorClass(item.title)">{{ item.title }}</v-chip>
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </LeaDropdown>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            lg="2"
                            class="pb-3">
                            <LeaDropdown
                                type="select"
                                :disabled="!canEditExtended || get('leaMaster') || create"
                                :formScope="formScope"
                                :step="1"
                                fieldName="language"
                                :label="$t('vvtProcessEditor.basics.language.label')"
                                :hint="$t('vvtProcessEditor.basics.language.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.language.helpMode')"
                                :create="create"
                                v-model="languageModel"
                                @help-mode="$emit('help-mode', $event)"
                                :items="languages"
                                :required="true" />
                        </v-col>
                        <v-col 
                            cols="12"
                            class="pb-3">
                            <LeaTextField
                                :disabled="!canEdit"
                                fieldName="name"
                                :step="1"
                                :formScope="formScope"
                                required
                                :label="$t('vvtProcessEditor.basics.name.label')"
                                :hint="$t('vvtProcessEditor.basics.name.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.name.helpMode')"
                                v-model="nameModel"
                                @help-mode="$emit('help-mode', $event)"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="mb-3"
                        >
                            <LeaTextArea
                                :disabled="!canEdit"
                                :formScope="formScope"
                                :step="1"
                                fieldName="description"
                                :label="$t('vvtProcessEditor.basics.description.label')"
                                :hint="$t('vvtProcessEditor.basics.description.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.description.helpMode')"
                                :create="create"
                                v-model="descriptionModel"
                                @help-mode="$emit('help-mode', $event)"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            class="mb-3"
                        >
                            <LeaTextArea
                                :disabled="!canEdit"
                                :formScope="formScope"
                                :step="1"
                                fieldName="processDocumentation"
                                :label="$t('vvtProcessEditor.basics.processDocumentation.label')"
                                :hint="$t('vvtProcessEditor.basics.processDocumentation.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.processDocumentation.helpMode')"
                                :create="create"
                                v-model="processDocumentationModel"
                                @help-mode="$emit('help-mode', $event)"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <Assignment
                :formScope="formScope"
                :template="template"
                :create="create"
                :forcedLanguage="forcedLanguage"
                :recordLanguage="recordLanguage"
                :contactItemCategories="contactItemCategories"
                @help-mode="$emit('help-mode', $event)"
                :leaMaster="get('leaMaster')"
            />
            <v-toolbar dense flat color="transparent" style="min-height:72px;">
                <v-toolbar-title class="pl-0 pt-3 text-subtitle-1" v-t="'vvtProcessEditor.basics.keywordsAndResubmissionHeadline'" />
            </v-toolbar>
            <v-card color="transparent" flat tile class="px-2 mb-1">
                <v-card-text class="px-2 py-0 pb-3">
                    <v-row class="fill-height" no-gutters>
                        <v-col cols="12" md="6" :class="{ 'pb-3': $vuetify.breakpoint.smAndDown, 'pr-3': $vuetify.breakpoint.mdAndUp }">
                            <LeaDropdown
                                :type="$getDropdownTypeFor('internalKeywords', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                                return-object
                                :disabled="!canEdit || get('leaMaster')"
                                v-model="internalKeywordsModel"
                                :items="internalKeywordItems"
                                :formScope="formScope"
                                :step="1"
                                fieldName="internalKeywords"
                                :label="$t('vvtProcessEditor.basics.internalKeywords.label')"
                                :hint="$t('vvtProcessEditor.basics.internalKeywords.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.internalKeywords.helpMode')"
                                :create="create"
                                @help-mode="$emit('help-mode', $event)"
                                clearable
                                :required="false"
                                multiple
                                chips
                                deletable-chips
                                :delimiters="[',']"
                                :freetext-enabled-hint="$t('createItemHintComma')"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <LeaDate
                                :disabled="!canEdit || get('leaMaster')"
                                :formScope="formScope"
                                v-model="resubmissionModel"
                                :step="1"
                                fieldName="resubmission"
                                :label="$t('vvtProcessEditor.basics.resubmission.label')"
                                :hint="$t('vvtProcessEditor.basics.resubmission.hint')"
                                :helpModeText="$t('vvtProcessEditor.basics.resubmission.helpMode')"
                                @help-mode="$emit('help-mode', $event)"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-toolbar dense flat color="transparent" style="min-height:72px;">
                <v-toolbar-title class="pl-0 pt-3 text-subtitle-1">
                {{ $t('vvtProcessEditor.basics.toolsHeadline') }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card color="transparent" flat tile class="px-2 mb-1">
                <v-card-text class="px-2 py-0 pb-3">
                    <LeaDropdown
                        :type="$getDropdownTypeFor('tools', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                        return-object
                        :disabled="!canEdit"
                        v-model="toolsModel"
                        :items="toolItems"
                        :formScope="formScope"
                        :step="1"
                        fieldName="tools"
                        :label="$t('vvtProcessEditor.basics.tools.label')"
                        :hint="$t('vvtProcessEditor.basics.tools.hint')"
                        :helpModeText="$t('vvtProcessEditor.basics.tools.helpMode')"
                        :create="create"
                        @help-mode="$emit('help-mode', $event)"
                        clearable
                        :required="false"
                        multiple
                        chips
                        deletable-chips
                        :delimiters="[',']"
                        :freetext-enabled-hint="$t('createItemHintComma')"
                    />
                </v-card-text>
            </v-card>
        </v-card>
        <div>
            <Enclosures
                class="mt-12 relative"
                :formScope="formScope"
                @help-mode="$emit('help-mode', $event)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Assignment from './Assignment.vue';
import LeaSwitch from '@/components/Input/LeaSwitch';
import LeaTextField from '@/components/Input/LeaTextField';
import LeaTextArea from '@/components/Input/LeaTextArea';
import LeaDropdown from '@/components/Input/LeaDropdown';
import LeaDate from '@/components/Input/LeaDate';
import Enclosures from '../Enclosures';
import ModelMixin from '@/components/vvt/ModelMixin';

export default {
    name: 'Basics',
    mixins: [ModelMixin],
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        formScope: {
            type: String,
            default: null
        },
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        recordLanguage: {
            type: String,
            default: null
        },
        forcedLanguage: {
            type: String,
            default: null
        },
        contactItemCategories: {
            type: Array,
            default: () => []
        },
    },
    components: {
        Assignment,
        LeaSwitch,
        LeaTextArea,
        LeaDropdown,
        LeaTextField,
        LeaDate,
        Enclosures
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setProperty'
        }),
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            internalKeywordItems: 'companyInternalKeywords/getItems',
            languages: 'companyLanguage/getItems',
            toolItems: 'tools/getItems',
        }),
        canEdit () {
            return this.get('canEdit');
        },
        canEditExtended () {
            return this.get('canEditExtended');
        },
        useAuthorityReportRelevance () {
            if (this.template) {
                return false;
            }
            return this.getCompanyGroupSettings('useAuthorityReportRelevance');
        },
        nameModel: {
            get() {
                return this.get('name');
            },
            set(val) {
                this.set({ property: 'name', data: val });
            },
        },
        languageModel: {
            get() {
                return this.recordLanguage;
            },
            set(val) {
                if (val) {
                    this.$emit('on-changed-record-language', val);
                }
            },
        },
        authorityReportRelevanceModel: {
            get() {
                return this.get('authorityReportRelevance');
            },
            set(val) {
                this.set({ property: 'authorityReportRelevance', data: val });
            },
        },
        statusModel: {
            get() {
                return this.get('status');
            },
            set(val) {
                this.set({ property: 'status', data: val });
            },
        },
        priorityModel: {
            get() {
                return this.get('priority');
            },
            set(val) {
                this.set({ property: 'priority', data: val });
            },
        },
        descriptionModel: {
            get() {
                return this.get('description');
            },
            set(val) {
                this.set({ property: 'description', data: val });
            },
        },
        processDocumentationModel: {
            get() {
                return this.get('processDocumentation');
            },
            set(val) {
                this.set({ property: 'processDocumentation', data: val });
            },
        },
        internalIdModel: {
            get() {
                return this.get('internalId');
            },
            set(val) {
                this.set({ property: 'internalId', data: val });
            },
        },
        internalKeywordsModel: {
            get() {
                return this.getValue(this.internalKeywordItems, this.get('internalKeywords'));
            },
            set(val) {
                const data = this.setValue(
                    this.internalKeywordItems,
                    val,
                    null,
                    null,
                    'companyInternalKeywords/unshiftItems'
                );
                this.set({ property: 'internalKeywords', data: data });
            },
        },
        toolsModel: {
            get() {
                return this.getValue(this.toolItems, this.get('tools'));
            },
            set(val) {
                const data = this.setValue(
                    this.toolItems,
                    val,
                    null,
                    {
                        cloud: 'NOT_EXEMPTED',
                        company: '',
                        country: '',
                    },
                    'tools/addTool'
                );
                this.set({ property: 'tools', data: data });
            },
        },
        resubmissionModel: {
            get() {
                return this.get('resubmission');
            },
            set(val) {
                this.set({ property: 'resubmission', data: val });
            },
        },
    },
};
</script>
