<template>
  <v-card
    @click.passive="emitHelpMode"
    @focus.capture="emitHelpMode"
    align-start
    class="default"
    height="100%"
    elevation="1"
  >
      <v-card-text class="px-0 pb-0">
        <v-combobox
            :items="items"
            :value="value"
            @input="$emit('input', $event)"
            @change="$emit('modified', $event)"
            :label="label"
            :hint="hint"
            :item-value="itemValue"
            :item-text="itemText"
            persistent-hint
            :filled="filled"
            background-color="transparent"
            :clearable="clearable"
            :no-data-text="$t('noData')"
            :error-messages="
                errors.collect(
                    formScope + '.' + fieldName + rowId
                )
            "
            :data-vv-name="fieldName + rowId"
            :data-vv-scope="formScope"
            v-validate="required ? 'required' : ''"
            :required="required"
            :chips="chips"
            :deletable-chips="deletableChips"
            :delimiters="delimiters"
            :multiple="multiple"
            :disabled="loading || disabled || disabledComp"
            :loading="loading"
            :hide-selected="selectedList"
            :menu-props="selectedList ? menuPropsList : menuPropsDefault"
            spellcheck="true"
            v-clear-input-on-change="!disableClearInputOnChange"
            v-clear-input-on-blur="!disableClearInputOnBlur"
            @update:search-input="onType()"
            @blur="enableSelectAll ? setFilteredItems() : null"
            :ref="'LeaCombobox_' + fieldName + rowId"
        >
            <template v-if="freetextEnabledHint" v-slot:no-data>
                <v-alert
                    type="success"
                    class="ma-0 text-body-2"
                    tile
                    v-text="freetextEnabledHint"
                />
            </template>
            <template v-if="enableSelectAll" v-slot:prepend-item>
                <slot name="prependSelectAll" />
                <v-list-item
                    ripple
                    @click.stop="toggleSelectAll()"
                    v-if="showSelectAll"
                >
                    <v-list-item-action>
                        <v-icon
                            :color="value.length > 0 ? 'primary' : ''"
                            >{{ selectAllIcon }}</v-icon
                        >
                    </v-list-item-action>
                    <v-list-item-title>{{
                        $t('selectAll')
                    }}</v-list-item-title>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-outer>
                <v-tooltip v-if="!helpMode && companyGroupHelp && companyGroupHelp.fieldIcon" bottom color="primary">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    icon
                    large
                    color="primary"
                    class="mr-3"
                    @click="setHelpMode(true)"
                    >
                    <v-icon v-text="companyGroupHelp.fieldIcon" />
                    </v-btn>
                </template>
                    {{ $t('furtherInformation') }}
                </v-tooltip>
            </template>
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
        </v-combobox>
        <template v-if="selectedList && value.length">
            <v-card 
                    :disabled="loading || disabled || disabledComp"
                    class="default" flat height="100%"
            >
                <v-toolbar flat dense tile color="secondary">
                    <v-toolbar-title
                        class="text-subtitle-1 primary--text"
                    >
                    <v-icon color="primary">mdi-format-align-bottom</v-icon>
                    {{ selectedListTitle }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <template v-for="(item, index) in value">
                    <v-hover :key="index">
                        <v-card
                            slot-scope="{ hover }"
                            ripple
                            @click="removeItem(item)"
                            flat
                        >
                            <v-card-text>
                                <div v-html="itemText(item)" class="pl-3" :class="`${hover ? 'line-through' : ''}`" />
                            </v-card-text>
                            <v-card-actions class="vertical-center">
                                <v-icon small v-if="hover" color="error" class="pt-1">mdi-close</v-icon>
                                <v-icon small v-else color="primary" class="pt-1">mdi-menu-right</v-icon>
                            </v-card-actions>
                        </v-card>
                    </v-hover>
                </template>
            </v-card>
        </template>
      </v-card-text>
  </v-card>
</template>

<script>
import { uniq } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
const MAIN_ENTITY = 'App\\Entity\\ProcessingActivity\\ProcessingActivity';

export default {
    inject: {
        $validator: '$validator',
    },
    data () {
        return {
            disabledComp: false,
            showSelectAll: false,
            filteredItems: [],
            menuPropsDefault: { closeOnClick: false, closeOnContentClick: false, disableKeys: true, openOnClick: false, maxHeight: 304 },
            menuPropsList: { closeOnClick: false, closeOnContentClick: true, disableKeys: true, openOnClick: false, maxHeight: 304, contentClass: 'v-select--column-selections', allowOverflow: true }
        }
    },
    methods: {
        ...mapActions({
            setHelpMode: 'processingActivityModel/setHelpModeState',
        }),
        disable () {
            this.disabledComp = true;
        },
        enable () {
            this.disabledComp = false;
        },
        onType() {
            if (this.enableSelectAll) {
                this.showSelectAll = this.$refs['LeaCombobox_' + this.fieldName + this.rowId].hasDisplayedItems;
            }
        },
        setFilteredItems() {
            this.filteredItems = this.$refs['LeaCombobox_' + this.fieldName + this.rowId].filteredItems;
        },
        toggleSelectAll() {
            this.$nextTick(() => {
                if (this.allSelected) {
                    this.$emit('input', []);
                } else {
                    let value = this.filteredItems.length
                        ? uniq([...this.value, ...this.filteredItems])
                        : uniq([...this.items]);

                    this.$emit('input', value);
                }
                this.$refs['LeaCombobox_' + this.fieldName + this.rowId].isFocused = false;
            });
        },
        removeItem (item) {
            const newValue = this.value.filter(
                x => x.id ? x.id !== item.id : x.clientId !== item.clientId
            );
            this.$emit('input', newValue);
        },
        emitHelpMode () {
            this.$emit('help-mode', {
                title: this.label,
                text: this.helpModeText,
                fieldName: this.fieldName,
                childFieldName: this.childFieldName
            })
        }
    },
    computed: {
        ...mapGetters({
            helpMode: 'processingActivityModel/getHelpModeState',
            getCompanyHelp: 'companyGroupHelp/getByEntityAndFieldName',
        }),
        companyGroupHelp() {
            return this.getCompanyHelp(MAIN_ENTITY, this.fieldName, this.childFieldName);
        },
        allSelected() {
            return this.value.length === this.items.length;
        },
        someSelected() {
            return this.value.length > 0 && !this.allSelected;
        },
        selectAllIcon() {
            if (this.allSelected) return 'check_box';
            if (this.someSelected) return 'indeterminate_check_box';
            return 'check_box_outline_blank';
        }
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        create: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        value: {
            type: [Number, String, Object, Array],
            default: null,
        },
        items: {
          type: Array,
          default: () => []
        },
        itemValue: {
            type: Function,
            default: function(e) {
                return e.clientId && !e.id ? e.clientId : e.id
            },
        },
        itemText: {
            type: Function,
            default: function(e) {
                return e.title
            },
        },
        filled: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        deletableChips: {
            type: Boolean,
            default: false,
        },
        chips: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
        freetextEnabled: {
            type: Boolean,
            default: false,
        },
        freetextEnabledHint: {
            type: String,
            default: null,
        },
        delimiters: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableClearInputOnChange: {
            type: Boolean,
            default: false,
        },
        disableClearInputOnBlur: {
            type: Boolean,
            default: false,
        },
        enableSelectAll: {
            type: Boolean,
            default: false,
        },
        selectedList: {
          type: Boolean,
          default: false
        },
        selectedListTitle: {
          type: String,
          default: 'Selected'
        },
    },
    watch: {
        disabled (val) {
            this.disabledComp = val;
        }
    }
};
</script>
