<template>
    <v-card flat tile color="secondary" class="pb-3">
        <RowHeading
            :headline="$t('vvtProcessEditor.basics.enclosures.title')"
            :button-text="$t('vvtProcessEditor.basics.enclosures.add')"
            :disabled="!canEdit || get('leaMaster')"
            @click:add="addRow"
        />
        <div v-if="processingActivityModel.enclosures && processingActivityModel.enclosures.length" class="pl-6">
            <EnclosureItem
                v-for="(enclosure, i) in processingActivityModel.enclosures"
                :key="enclosure.id || enclosure.clientId"
                :rowId="enclosure.id || enclosure.clientId"
                :formScope="formScope"
                :enclosure="enclosure"
                :canEdit="canEdit && !get('leaMaster')"
                :i="i"
                class="relative"
                @help-mode="$emit('help-mode', $event)"
            />
        </div>
        <div v-else class="ma-3">
            <v-btn
                :disabled="!canEdit || get('leaMaster')"
                @click="addRow"
                color="success"
                x-large
                block
                text
                outlined
                class="btn--justify-content-start text-transform--none"><v-icon left>mdi-plus</v-icon>{{ $t('vvtProcessEditor.basics.enclosures.addHint') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { kebabCase } from 'lodash';
import RowHeading from '@/components/vvt/ProcessEditor/Row/Heading.vue';
import EnclosureItem from './EnclosureItem.vue';

export default {
    name: 'Enclosures',
    components: {
        RowHeading,
        EnclosureItem,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        formScope: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            processingActivityModel: 'processingActivityModel/getModel',
        }),
        canEdit () {
            return this.get('canEdit');
        },
    },
    data() {
        return {
            name: kebabCase(this.$options.name),
        };
    },
    methods: {
        addRow() {
            this.$store.dispatch('processingActivityModel/addEnclosure');
        },
    },
};
</script>
