<template>
    <div class="mx-n4">
        <v-card
            color="tertiary"
            tile
            class="px-2"
            flat
            @click.passive="
                $emit('help-mode', {
                    title: $t('vvtProcessEditor.basics.contactItems.title'),
                    text: $t('vvtProcessEditor.basics.contactItems.helpMode'),
                    fieldName: 'contactItems',
                    childFieldName: null
                })
            "
            @focus.capture="
                $emit('help-mode', {
                    title: $t('vvtProcessEditor.basics.contactItems.title'),
                    text: $t('vvtProcessEditor.basics.contactItems.helpMode'),
                    fieldName: 'contactItems',
                    childFieldName: null
                })
            ">
            <v-toolbar dense flat color="transparent" style="min-height:72px;">
                <v-toolbar-title class="pl-0 pt-3 text-subtitle-1" v-t="'vvtProcessEditor.basics.contactItems.title'" />
            </v-toolbar>
            <v-autocomplete
                :flat="!canEdit"
                :items="searchPersonItems"
                @keydown="$wait.start('wait for search persons ' + compId)"
                :disabled="!canEdit"
                :loading="$wait.is('search persons ' + compId)"
                :search-input.sync="searchPersons"
                @input="addToModel($event)"
                hide-selected
                solo
                item-text="displayName"
                item-value="id"
                :label="$t('vvtProcessEditor.basics.persons.add.label')"
                return-object
                clearable
                no-filter
                ref="searchInput"
                class="pt-5 pb-0 px-3"
                color="success"
                prepend-inner-icon="mdi-plus"
            >
            <template v-slot:no-data>
                <div class="my-n2">
                    <p v-if="(!searchPersons || searchPersons.length < 2)" class="ma-3" v-t="'vvtProcessEditor.basics.persons.add.hint'" />
                    <p v-else-if="($wait.is('search persons ' + compId) || $wait.is('wait for search persons ' + compId))" class="ma-3" v-t="'searching'" />
                    <div v-else>
                        <p v-if="$ssoMode || !$hasRole('ROLE_TENANT_ADMIN')" class="ma-3" v-t="'vvtProcessEditor.basics.persons.noResults'" />
                        <div v-else>
                            <v-card class="pa-0">
                                <v-container fluid class="pa-3">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-alert
                                                type="info"
                                                icon="mdi-information-outline"
                                                elevation="2"
                                                >
                                                {{ $t('vvtProcessEditor.basics.persons.noResults')  }}
                                                {{ $t('vvtProcessEditor.basics.persons.createHint')  }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" md="6" xl="3" class="pl-0">
                                            <v-card light class="mb-3">
                                                <v-text-field v-model="personFirstName" filled hide-details background-color="transparent" :disabled="$wait.is('create contact')" :label="$t('vvtProcessEditor.basics.persons.employeeFirstName')" />
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6" xl="3" class="pl-0">
                                            <v-card light class="mx-3">
                                                <v-text-field v-model="personLastName" filled hide-details background-color="transparent" :disabled="$wait.is('create contact')" :label="$t('vvtProcessEditor.basics.persons.employeeLastName')" />
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-btn color="success" @click="createPersonForContactItem()" :disabled="!personFirstName || !personFirstName.length || !personLastName || !personLastName.length  || $wait.is('create contact')" :loading="$wait.is('create contact')">{{ $t('vvtProcessEditor.basics.persons.createEmployee') }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:item="{ item, on }">
                <v-hover v-slot:default="{ hover }" v-on="on" :disabled="!canModifyContact(item.id)">
                    <v-list-item v-on="{ hover }" :two-line="!!item.title === true || !canModifyContact(item.id)" :three-line="!!item.title === true && !canModifyContact(item.id)" :color="canModifyContact(item.id) ? 'success' : 'grey'" :input-value="hover" :disabled="!canModifyContact(item.id)">
                        <v-list-item-avatar>
                            <v-avatar v-if="!hover" :color="canModifyContact(item.id) ? 'primary' : 'grey'"><span class="white--text text-subtitle-1">{{ item.firstName.substring(0,1) + item.lastName.substring(0,1) }}</span></v-avatar>
                            <v-avatar v-else color="success"><v-icon x-large color="white">mdi-plus</v-icon></v-avatar>
                        </v-list-item-avatar>     
                        <v-list-item-content>
                            <v-list-item-subtitle v-if="item.title" v-text="item.title"></v-list-item-subtitle>
                            <v-list-item-title>{{ item.firstName }} {{ item.lastName }} <small v-if="item.jobTitle">({{item.jobTitle}})</small></v-list-item-title>
                            <v-list-item-title v-if="!canModifyContact(item.id)"><v-alert type="warning" dense text border="left">{{ $t('vvtProcessEditor.basics.persons.add.notAllowed') }}</v-alert></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-hover>
            </template>
            </v-autocomplete>
            <v-data-table
                v-if="model && model.length"
                :headers="personHeaders"
                :items="model"
                disable-pagination
                hide-default-footer
                item-key="id"
                class="tertiary"
            >
            <template v-slot:[`item.category`]="{ item }">
                <v-select
                    :disabled="categoryDisabled || !canModifyContact(item.contactId)"
                    v-model="item.category"
                    :label="$t('vvtProcessEditor.basics.persons.category.label')"
                    :items="contactItemCategories"
                    item-text="title"
                    item-value="id"
                    solo
                    dense
                    flat
                    full-width
                    hide-details
                    outlined
                    filled
                    background-color="transparent"
                    hide-selected
                    class="white"
                    @change="$emit('input', model)"
                    >
                </v-select>
            </template>
            <template v-slot:[`item.remove`]="{ item }">
                <v-btn
                :disabled="!canEdit || !canModifyContact(item.contactId)"
                icon
                @click="addRemoveCandidate(item)"
                color="red"
                >
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            </v-data-table>
        </v-card>
        <BaseConfirmationDialog
            v-model="removeItemConfirmation"
            :title="$t('rowItem.removeConfirmation.headline')"
            :text="$t('rowItem.removeConfirmation.text')"
            :confirmationText="$t('rowItem.removeConfirmation.delete')"
            :cancelText="$t('rowItem.removeConfirmation.cancel')"
            @cancel="removeItemConfirmation = false"
            @confirm="removeCandidateFromPersonsModel()"
        />
    </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        recordLanguage: {
            type: String,
            default: null
        },
        forcedLanguage: {
            type: String,
            default: null
        },
        create: {
            type: Boolean,
            default: false
        },
        fixedCategory: {
            type: Number,
            default: null
        },
        compId: {
            type: String,
            default: 'default'
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data () {
        return {
            searchPersonItems: [],
            searchPersons: null,
            personFirstName: null,
            personLastName: null,
            removeCandidate: null,
            removeItemConfirmation: false
        }
    },
    computed: {
        ...mapGetters({
            getContactItemCategories: 'contactItemCategories/getItems',
        }),
        arrTypedPersonName () {
            if(!this.searchPersons || this.searchPersons.length < 2) {
                return ['',''];
            }
            let arrInput = this.searchPersons.split(' ');
            return [arrInput.shift(), arrInput.join(' ')];
        },
        categoryDisabled() {
            return !this.canEdit || !!this.fixedCategory !== false;
        },
        contactItemCategories() {
            if (this.fixedCategory === 1 || this.fixedCategory === 2 || this.fixedCategory === 3) {
                return this.getContactItemCategories.filter(x => x.category = this.fixedCategory);
            }
            return this.getContactItemCategories;
        },
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
                this.$emit('modified', val);
            }
        },
        personHeaders () {
            return [
                { text: this.$t('vvtProcessEditor.basics.persons.name'), align: 'start', value: 'fullName' },
                { text: this.$t('vvtProcessEditor.basics.persons.jobTitle'), align: 'start', value: 'jobTitle' },
                { text: this.$t('vvtProcessEditor.basics.persons.category.label'), align: 'end', value: 'category', width: this.$vuetify.breakpoint.smAndDown ? '140' : '420' },
                { text: this.$t('vvtProcessEditor.basics.persons.remove'), align: 'end', value: 'remove', width: this.$vuetify.breakpoint.smAndDown ? '100' : this.$vuetify.breakpoint.lgAndUp ? '240' : '120' },
            ];
        },
    },
    methods: {
        ...mapActions({
            fetchContactItemCategories: 'contactItemCategories/fetch',
            clearContactItemCategories: 'contactItemCategories/clearItems',
            searchStaff: 'staff/search',
            createStaff: 'staff/create',
        }),
        canModifyContact(id) {
            return this.$hasRole('ROLE_TENANT_EDITOR') || this.$hasRole('ROLE_CREATOR') || id !== this.$myContactId();
        },
        createPersonForContactItem () {
            if(this.personFirstName && this.personLastName) {
                this.$wait.start('create contact');
                this.createStaff({
                    firstName: this.personFirstName,
                    lastName: this.personLastName
                }).then(x => {
                    if(x && x.data) {
                        this.addToModel({
                            id: x.data.id,
                            firstName: x.data.firstName,
                            lastName: x.data.lastName,
                            title: x.data.title,
                            jobTitle: x.data.jobTitle
                        });
                    }
                    this.searchPersons = null;
                    this.$wait.end('create contact');
                });
            }
        },
        fetchSearchedPersons (val) {
            this.searchPersonItems = [];
            this.$wait.start('search persons ' + this.compId);
            this.searchStaff({q: val}).then(x => {
            if (x.data && x.data.length) {
                this.searchPersonItems = x.data.map(person => {
                let strPre = person.title ? person.title + ' ' : '';
                person.displayName = strPre + (person.firstName ? person.firstName : '') + ' ' + (person.lastName ? person.lastName : '');
                return person;
                })
            } else {
                this.personFirstName = this.arrTypedPersonName[0];
                this.personLastName = this.arrTypedPersonName[1];
            }
            })
            .catch(() => {
                // console.log(err)
            })
            .finally(() => {
                this.$wait.end('search persons ' + this.compId);
                this.$wait.end('wait for search persons ' + this.compId);
            });
        },
        addToModel (person) {
            if (person && this.canModifyContact(person.id)) {
                let newPerson = {...person, ...{
                    id: null,
                    clientId: uuid(),
                    contactId: person.id,
                    category: this.fixedCategory ? this.fixedCategory : 2,
                    firstName: person.firstName,
                    lastName: person.lastName,
                    jobTitle: person.jobTitle,
                    title: person.title
                }}
                this.model = [...this.model, ...[newPerson]]
                this.searchPersonItems = [];
            }
            this.$refs.searchInput.lazySearch = '';
            this.$refs.searchInput.lazyValue = null;
        },
        addRemoveCandidate (contactItem) {
            this.removeCandidate = contactItem;
            this.removeItemConfirmation = true;
        },
        removeCandidateFromPersonsModel () {
            if (this.model && this.removeCandidate) {
                const index = this.model.findIndex(x => {
                    if (x.id && x.id === this.removeCandidate.id) {
                    return x;
                    }
                    return x.clientId && x.clientId === this.removeCandidate.clientId;
                });
                if (index > -1) {
                    let tmp = this.model;
                    tmp.splice(index, 1);
                    this.model = tmp;
                    this.removeCandidate = null;
                    this.removeItemConfirmation = false;
                }
            }
        },
        async fetchLanguageBasedItems() {
            let payload = {};
            if (this.forcedLanguage) {
                payload.forcedLanguage = this.forcedLanguage;
            }
            if (this.create) {
                payload.forcedLanguage = this.recordLanguage;
            }
            await Promise.all([
                this.fetchContactItemCategories(payload)
            ])
        }
    },
    watch: {
        searchPersons: debounce(function(val, oldVal) {
            // at least two characters
            if (!val || val.length < 2) return

            if (oldVal && val.trim() === oldVal.trim()) return

            // Items have already been requested
            if (this.$wait.is('search persons ' + this.compId)) return
            
            this.fetchSearchedPersons(val);
        }, 800),
        recordLanguage(val, oldVal) {
            if (val !== oldVal) {
                this.fetchLanguageBasedItems();
            }
        },
        forcedLanguage(val, oldVal) {
            if (val !== oldVal) {
                this.fetchLanguageBasedItems();
            }
        }
    },
    mounted() {
        this.fetchLanguageBasedItems();
    },
    beforeDestroy() {
        if (this.forcedLanguage) {
            this.clearContactItemCategories();
        }
    }
}
</script>