<template>
    <div class="mr-n1">
        <v-card color="tertiary" :flat="false" class="mb-12" height="100%">
            <v-card-text>
                <div class="text-right mb-3">
                    <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on: onToolTip }">
                            <div v-on="onToolTip" class="d-inline-block mx-2">
                                <v-btn
                                    :disabled="!canEdit"
                                    @click="deletionDialog = true"
                                    color="error"
                                    small
                                    fab
                                    depressed
                                    outlined
                                >
                                    <v-icon small>clear</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span v-t="canEdit ? 'remove' : 'notAuthorizedAction'" />
                    </v-tooltip>
                </div>
                <v-row>
                    <v-col cols="12">
                        <LeaTextField
                            :step="1"
                            :formScope="formScope"
                            :rowId="rowId"
                            fieldName="enclosure"
                            childFieldName="title"
                            :label="$t('vvtProcessEditor.basics.enclosures.enclosureItem.title.label')"
                            :hint="$t('vvtProcessEditor.basics.enclosures.enclosureItem.title.hint')"
                            :helpModeText="$t('vvtProcessEditor.basics.enclosures.enclosureItem.title.helpMode')"
                            v-model="titleModel"
                            required
                            :disabled="!canEdit"
                            @help-mode="$emit('help-mode', $event)"
                            @input="changeProperty('title', $event)"
                        />
                    </v-col>
                    <v-col cols="12">
                        <LeaTextArea
                            :step="1"
                            :formScope="formScope"
                            :rowId="rowId"
                            fieldName="enclosure"
                            childFieldName="description"
                            :label="$t('vvtProcessEditor.basics.enclosures.enclosureItem.description.label')"
                            :hint="$t('vvtProcessEditor.basics.enclosures.enclosureItem.description.hint')"
                            :helpModeText="$t('vvtProcessEditor.basics.enclosures.enclosureItem.description.helpMode')"
                            v-model="descriptionModel"
                            :disabled="!canEdit"
                            @help-mode="$emit('help-mode', $event)"
                            @input="changeProperty('description', $event)"
                        />
                    </v-col>
                    <v-col cols="12" md="12" lg="12">
                        <v-card
                            @click.passive="
                                $emit('help-mode', {
                                    title: $t('vvtProcessEditor.basics.enclosures.enclosureItem.files.label'),
                                    text: $t('vvtProcessEditor.basics.enclosures.enclosureItem.files.helpMode'),
                                    fieldName: 'enclosure',
                                    childFieldName: 'files'
                                })
                            "
                            @focus.capture="
                                $emit('help-mode', {
                                    title: $t('vvtProcessEditor.basics.enclosures.enclosureItem.files.label'),
                                    text: $t('vvtProcessEditor.basics.enclosures.enclosureItem.files.helpMode'),
                                    fieldName: 'enclosure',
                                    childFieldName: 'files'
                                })
                            "
                            align-start
                            class="default"
                            height="100%"
                            elevation="1">
                            <v-card-text class="pa-0">
                                <vue-dropzone
                                    :ref="'myVueDropzone' + i"
                                    :id="'dropzone' + i"
                                    :options="dropzoneOptions"
                                    v-model="filesModel"
                                    v-on:vdropzone-sending="sendFile"
                                    v-on:vdropzone-success="addUpload"
                                    v-on:vdropzone-removed-file="removeUpload"
                                ></vue-dropzone>
                                <div class="v-messages v-messages--top-border pl-2 pb-2">
                                    <div class="v-messages__wrapper">
                                        <div class="v-messages__message">
                                            <span v-t="'vvtProcessEditor.basics.enclosures.enclosureItem.files.hint'" />
                                        </div>
                                    </div>
                                </div>
                                <v-list v-if="filesModel.length">
                                    <v-list-item
                                        v-for="file in filesModel"
                                        :key="file.uuid"
                                        @click="$getFile(`/api/enclosures/download/${file.uuid}`, file.filename, true)"
                                        :disabled="$wait.is('fetching file')"
                                    >
                                        <v-list-item-action>
                                            <v-icon color="primary"
                                                >cloud_download</v-icon
                                            >
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="file.filename"
                                            ></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-tooltip left color="error">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        @click="deleteItem(file)"
                                                        color="error"
                                                        icon
                                                        :disabled="!canEdit"
                                                    >
                                                    <v-icon
                                                        color="error"
                                                        >mdi-close</v-icon
                                                    >
                                                    </v-btn>
                                                </template>
                                                <span v-t="'remove'" />
                                            </v-tooltip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <BaseConfirmationDialog
                v-model="deletionDialog"
                :title="$t('vvtProcessEditor.basics.enclosures.removeHeadline')"
                :text="$t('vvtProcessEditor.basics.enclosures.removeMessage')"
                :confirmation-text="$t('remove')"
                :cancel-text="$t('cancel')"
                :button-color-confirm="'warning'"
                @cancel="deletionDialog = false"
                @confirm="removeEnclosure()"
            />
        </v-card>
    </div>
</template>

<script>
import { kebabCase } from 'lodash';
import vueDropzone from 'vue2-dropzone';
import LeaTextField from '@/components/Input/LeaTextField';
import LeaTextArea from '@/components/Input/LeaTextArea';
import { getToken } from '@/utils/auth';

export default {
    name: 'EnclosureItem',
    components: {
        LeaTextField,
        LeaTextArea,
        vueDropzone,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        enclosure: {
          type: Object,
          default: () => {}
        },
        rowId: {
            type: [Number, String],
            default: null,
        },
        i: {
            type: Number,
            default: null,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            name: kebabCase(this.$options.name),
            deletionDialog: false,
            filesModel: this.enclosure.files,
        };
    },
    computed: {
        titleModel: {
            get() {
                return this.enclosure && this.enclosure.title ? this.enclosure.title : null;
            },
            set(val) {
                this.$set(this.enclosure, 'title', val);
            }
        },
        descriptionModel: {
            get() {
                return this.enclosure && this.enclosure.description ? this.enclosure.description : null;
            },
            set(val) {
                this.$set(this.enclosure, 'description', val);
            }
        }
    },
    created () {
        const uploadHeaders = {
            'Cache-Control': null,
            'X-Requested-With': null,
            Accept: `application/json, application/prs.lea+json;v=${process.env.VUE_APP_VERSION}`
        }

        if (process.env.VUE_APP_SSO !== 'true') {
            uploadHeaders.Authorization = `Bearer ${getToken()}`
        }

        this.dropzoneOptions = {
            headers: uploadHeaders,
            dictDefaultMessage: this.$i18n.t(
                'vvtProcessEditor.basics.enclosures.enclosureItem.files.label'
            ),
            addRemoveLinks: true,
            url: `${process.env.VUE_APP_BASE_URL}/api/enclosures/upload`,
            thumbnailHeight: 120,
            maxFilesize: 20,
            createImageThumbnails: false,
            acceptedFiles:
                'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptm,.pptx',
        };
    },
    mounted () {
        if (!this.canEdit) {
            this.$refs['myVueDropzone' + this.i].disable();
        }
    },
    methods: {
        deleteItem(file) {
            this.filesModel.splice(this.filesModel.indexOf(file), 1);
        },
        sendFile(file, xhr, formData) {
            formData.append('clientId', file.upload.uuid);
            formData.append('type', 'ENCLOSURE');
        },
        addUpload(file, response) {
            this.filesModel.unshift({ ...file.upload, ...response });
            this.$emit('modified');
            this.$refs['myVueDropzone' + this.i].removeFile(file);
        },
        removeUpload() {
            this.$emit('modified');
        },
        removeEnclosure() {
            this.deletionDialog = false;
            this.$store.dispatch(
                'processingActivityModel/removeEnclosure',
                this.i
            );
        },
        changeProperty(property, value) {
            this.$store.dispatch(
                'processingActivityModel/setEnclosureProperty',
                {
                    index: this.i,
                    property: property,
                    data: value,
                }
            );
        },
    },
    watch: {
        filesModel(value) {
            this.$store.dispatch(
                'processingActivityModel/setEnclosureProperty',
                {
                    index: this.i,
                    property: 'files',
                    data: value,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.dropzone {
    min-height: 145px;
    border-right: 0;
    border-left: 0;
}
</style>
